import {Box, HStack, Img} from "@chakra-ui/react";
import logo from '../logo.png';
import {useHeader} from "../../lib/HeaderContext";

type Props = {}

export const Header: React.FC<Props> = () => {
  const {leftPortalRef, rightPortalRef} = useHeader()
  return (
    <HStack w={"full"} h={12} bg={"white"} justify={'space-between'} boxShadow={'0 1px 2px 0 rgba(0,0,0,0.05)'} position={'sticky'} top={0} zIndex={999}>
      <Box w={10} ref={leftPortalRef}>
      </Box>
      <Img src={logo.src} w={24} />
      <Box w={10} ref={rightPortalRef} />
    </HStack>
  )
}
