import {Box, BoxProps} from "@chakra-ui/react";
import React, {ReactNode} from "react";
import {Property} from "csstype";
import Color = Property.Color;
import {Header} from "./Header";
import {Footer} from "./Footer";
import {MobileNav} from "./MobileNav";

type Props = {
  children: ReactNode;
  containerBg?: Color;
}

export const MobileContainer: React.FC<Props & BoxProps> = ({ children, containerBg, ...boxProps }) => {
  return (
    <Box backgroundColor={'#F3F3F3'} w={"full"} h={"full"}>
      <Box maxW={425} mx={'auto'} bg={containerBg || '#ECEBE6'} position={'relative'} minH={"full"} display={'flex'} flexDirection={'column'}>
        <Header />
        <Box display={'flex'} flexDirection={'column'} flex={1} position={'relative'}>
          <Box flex={1} position={'relative'} {...boxProps}>
            {children}
          </Box>
          <Footer />
          <MobileNav />
        </Box>
      </Box>
    </Box>
  )
}
