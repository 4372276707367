import {Text, TextProps} from '@chakra-ui/react';
import {ReactNode} from "react";
import {Color} from "../../lib/color";

const BaseText: TextProps = {
  fontWeight: 500,
  fontSize: '10px',
  lineHeight: '100%',
  fontFamily: 'Noto Sans JP'
}

type FontWeightProps = {
  // 700
  bold?: boolean
  // 900
  superBold?: boolean
}

type ColorProps = {
  text?: boolean
  black?: boolean
  yellow?: boolean
  gray?: boolean
  white?: boolean
  red?: boolean
  gold?: boolean
}

type FontSizeProps = {
  // 10
  min?: boolean
  // 11
  micro?: boolean
  // 12
  small?: boolean
  // 14
  base?: boolean
  // 16
  mid?: boolean
  // 20
  large?: boolean
  // 24
  big?: boolean
  // 32
  mega?: boolean
}

type LetterSpacingProps = {
  // letter-spacing: 1.5px
  spacingSmall?: boolean
  // letter-spacing: 0.1em
  spacingWide?: boolean
}

type TextAlignProps = {
  start?: boolean
  center?: boolean
  end?: boolean
}

type Props = FontWeightProps & ColorProps & FontSizeProps & LetterSpacingProps & TextAlignProps & {
  children?: ReactNode
}

export const CrownText = (props: Props & TextProps) => {
  const {
    children,
    bold, superBold,
    text, black, yellow, gray, white, red, gold,
    min, micro, small, base, mid, large, big, mega,
    spacingSmall, spacingWide,
    start, center, end,
    ...textProps
  } = props
  const color = black ? Color.black :
    yellow ? Color.yellow :
      gray ? Color.gray :
        white ? Color.white :
        red ? 'red' :
          gold ? Color.gold :
            text ? Color.text : 'inherit'

  const fontWeight = bold ? 700 : superBold ? 900 : 500

  const fontSize = min ? 10 :
    micro ? 11 :
      small ? 12 :
        base ? 14 :
          mid ? 16 :
            large ? 20 :
              big ? 24 :
                mega ? 32 : 'inherit'

  const letterSpacing = spacingSmall ? '1.5px' :
    spacingWide ? '0.1em' : 'inherit'

  const textAlign = start ? 'start' :
    center ? 'center' :
      end ? 'end' : 'center' // TODO inheritにしたい

  return (
    <Text
      {...BaseText}
      color={color}
      fontWeight={fontWeight}
      fontSize={fontSize}
      letterSpacing={letterSpacing}
      textAlign={textAlign}
      {...textProps}
    >{children}</Text>
  )
}
