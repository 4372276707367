import {VStack, Portal, Button} from "@chakra-ui/react";
import {useDisclosure} from "@chakra-ui/hooks";
import {SettingGroup, SettingGroupProps} from "../setting/SettingGroup";
import {useState} from "react";
import {useRouter} from "next/router";
import {useHeader} from "../lib/HeaderContext";
import {Icon} from "../icons";
import {FooterTabs} from "./Footer";
import {signOut} from "../../lib/auth";

type Props = {
  initKey?: 'init' | 'setting'
}

export const MobileNav = ({ initKey }: Props) => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const [navKey, setNavKey] = useState<keyof typeof navConfig>(initKey || 'init')
  const router = useRouter()
  const {leftPortalRef} = useHeader()

  const handleSignOut = async () => {
    signOut()
    await router.replace('/sign_in')
  }

  const navConfig: { [key: string]: SettingGroupProps[] } = {
    init: [
      {
        group: 'アカウントについて',
        items: [
          { icon: 'navIconId', label: '各種設定', onClick: () => setNavKey('setting') },
          { icon: 'navIconChild', label: 'お子さま情報', href: '/children' },
          { icon: 'navIconInvoice', label: '注文履歴', href: 'https://crown-box.jp/shop/customer/orders?page=setting' },
          { icon: 'navIconTicket', label: 'ともだちを招待', href: 'https://crown-box.jp/shop/customer/invite_coupons?page=setting' },
          { icon: 'navIconPoint', label: 'ポイント管理', href: 'https://crown-box.jp/shop/customer/subs_orders?page=edit-point' },
          { icon: 'navIconLogout', label: 'ログアウト', onClick: handleSignOut },
        ]
      },
      {
        group: 'サービスについて',
        items: [
          { icon: 'navIconContact', label: 'お問い合わせ', href: 'https://crown-box.jp/shop/contact/draft' },
          { icon: 'navIconTermOfService', label: 'ご利用規約', href: 'https://crown-box.jp/shop/customer_term' },
          { icon: 'navIconPrivacy', label: 'プライバシーポリシー', href: 'https://crown-box.jp/shop/privacy' },
        ]
      }
    ],
    setting: [
      {
        group: '各種設定',
        items: [
          { icon: 'navIconAccount', label: '会員情報の変更', href: '/setting/account' },
          { icon: 'navIconAddress', label: 'お届け先の変更', href: '/setting/address' },
          // Cognitoとecforceで乖離が生まれてしまうのでメールアドレスの変更を一旦閉じる
          // { icon: 'navIconContact', label: 'メールアドレスの変更', href: 'https://crown-box.jp/shop/customer/edit?page=edit-email' }, // メールアドレスの変更はEcforceのAPIで提供されていない
          { icon: 'navIconCycle', label: 'お届け頻度の変更', href: '/setting/children?page=change_cycle' },
          { icon: 'navIconPrivacy', label: 'パスワードの変更', href: '/setting/password' },
          { icon: 'navIconCard', label: 'お支払い方法の変更', href: '/setting/children?page=payment' },
          { icon: 'navIconCard', label: 'クレジットカード情報の登録・変更', href: 'https://crown-box.jp/shop/customer/cards' }, // カードの変更はEcforceのAPIで提供されていない
          { icon: 'navIconBell', label: 'お知らせ設定', href: '/setting/notice' },
          { icon: 'navIconInvoice', label: 'スキップについて', href: '/setting/children?page=skip' },
          { icon: 'navIconInvoice', label: '休会について', href: '/setting/children?page=cancel' },
        ]
      }
    ]
  }

  const handleClose = () => {
    if (navKey === 'init') {
      onClose()
    } else {
      setNavKey('init')
    }
  }

  // メニューを表示するのはフッターから飛べる先だけ
  if (!FooterTabs.map(item => item.href).includes(router.pathname)) return <></>

  return (
    <>
      {
        isOpen ? (
          <Portal containerRef={leftPortalRef}>
            <Button variant='ghost' onClick={handleClose}>
              <Icon icon={'arrowLeft'} w={1.5}/>
            </Button>
          </Portal>
        ) : (
          <Portal containerRef={leftPortalRef}>
            <Button variant='ghost' onClick={onOpen}>
              <Icon icon={'menu'} w={3}/>
            </Button>
          </Portal>
        )
      }
      {
        isOpen && (
          <VStack w={'full'} px={5} pb={20} bg='white' position={'absolute'} inset={0} overflow={'auto'}>
            {
              navConfig[navKey].map((groupProps) => (
                <SettingGroup key={groupProps.group} {...groupProps}/>
              ))
            }
          </VStack>
        )
      }
    </>
  )
}
