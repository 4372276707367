import {Box, BoxProps, Img} from '@chakra-ui/react'
import change from './change.png'
import boy from './boy.png'
import girl from './girl.png'
import arrowRight from './arrowRight.png'
import arrowRightG from './arrowRightG.png'
import arrowRightW from './arrowRightW.png'
import arrowLeft from './arrowLeft.png'
import arrowLeftW from './arrowLeftW.png'
import closeWhite from './closeWhite.png'
import time from './time.png'
import cloud from './cloud.png'
import postin from './postin.png'
import menu from './menu.png'
import calender from './calendar.svg'
import clipboard from './clipboard.png'
import tel from './tel.png'

import navIconChild from './navIcon/child.png'
import navIconContact from './navIcon/contact.png'
import navIconId from './navIcon/id.png'
import navIconInvoice from './navIcon/invoice.png'
import navIconLogout from './navIcon/logout.png'
import navIconPoint from './navIcon/point.png'
import navIconPrivacy from './navIcon/privacy.png'
import navIconTermOfService from './navIcon/termOfService.png'
import navIconTicket from './navIcon/ticket.png'
import navIconAccount from './navIcon/account.png'
import navIconAddress from './navIcon/address.png'
import navIconBell from './navIcon/bell.png'
import navIconCard from './navIcon/card.png'
import navIconCycle from './navIcon/cycle.png'

import React from "react";

const icons = {
  change,
  boy,
  girl,
  arrowRight,
  arrowRightG,
  arrowRightW,
  arrowLeft,
  arrowLeftW,
  closeWhite,
  time,
  cloud,
  postin,
  menu,
  calender,
  clipboard,
  tel,
  navIconChild,
  navIconId,
  navIconContact,
  navIconInvoice,
  navIconPoint,
  navIconLogout,
  navIconPrivacy,
  navIconTermOfService,
  navIconTicket,
  navIconAccount,
  navIconAddress,
  navIconBell,
  navIconCard,
  navIconCycle,
} as const

export type IconKeys = keyof typeof icons;

type Props = {
  icon: IconKeys
} & BoxProps;

export const Icon: React.FC<Props> = ({ icon, ...props }) => {
  return (
    <Box {...props}>
      <Img src={icons[icon].src} w="full" h='full' objectFit={'contain'} />
    </Box>
  )
}
