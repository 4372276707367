import {CrownText} from "../common/CrownText";
import {Divider, VStack} from "@chakra-ui/react";
import {SettingItem, SettingItemProps} from "./SettingItem";

export type SettingGroupProps = {
  group: string
  items: SettingItemProps[]
}

export const SettingGroup = (props: SettingGroupProps) => {
  const { group, items } = props;
  return (
    <VStack w={'full'} alignItems={'start'}>
      <CrownText pt={12} base gray bold>{group}</CrownText>
      <VStack w={'full'} divider={<Divider />} spacing={0}>
        {items.map((itemProps) => (
          <SettingItem key={itemProps.label} {...itemProps} />
        ))}
      </VStack>
    </VStack>
  )
}
