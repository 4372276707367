export const Color = {
  white: '#ffffff',
  lightGray: 'rgb(243, 243, 243)',
  greenGray: 'rgb(247 249 247)',
  gray: 'rgb(151 151 151)',
  text: '#2e3a2d',
  black: '#000000',
  yellow: '#e2943b',
  gold: 'rgb(182 151 73)'
}
