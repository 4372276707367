import {Box, HStack, Spacer} from "@chakra-ui/react";
import {Icon, IconKeys} from "../icons";
import {CrownText} from "../common/CrownText";
import NextLink from "next/link";

export type SettingItemProps = {
  label: string
  icon: IconKeys
  href?: string
  onClick?: () => void
}

export const SettingItem = (props: SettingItemProps) => {
  const { label, icon, href, onClick } = props;
  const InnerItem = () => (
    <HStack w={'full'} h={'60px'} cursor={'pointer'}>
      <Icon boxSize={8} icon={icon} />
      <CrownText base bold spacingWide>{label}</CrownText>
      <Spacer />
      <Icon w={1.5} icon={'arrowRight'} />
    </HStack>
  )

  if (href) {
    return (
      <NextLink href={href} passHref>
        <Box as={"a"} w={'full'}>
          <InnerItem />
        </Box>
      </NextLink>
    )
  } else {
    return (
      <Box onClick={onClick} w={'full'}>
        <InnerItem />
      </Box>
    )
  }
}