import {HStack, VStack} from "@chakra-ui/react";
import {useRouter} from "next/router";
import {CrownText} from "../common/CrownText";
import NextLink from "next/link";

type IconProps = {
  color: string
}

const HomeIcon = ({ color }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path fill={color} d="M15.03 18H2.97C1.35 18 0 16.632 0 14.994V8.424C0 7.596 0.36 6.786 0.99 6.228L7.02 0.756C8.19 -0.252 9.81 -0.252 10.98 0.756L17.01 6.228C17.64 6.768 18 7.596 18 8.424V14.994C17.91 16.722 16.65 18 15.03 18ZM9 1.854C8.73 1.854 8.46 1.944 8.19 2.124L2.16 7.596C1.89 7.776 1.8 8.136 1.8 8.514V15.084C1.8 15.714 2.34 16.272 2.97 16.272H14.94C15.57 16.272 16.11 15.732 16.11 15.084V8.514C16.11 8.154 15.93 7.884 15.75 7.596L9.72 2.124C9.54 1.944 9.27 1.854 9 1.854Z"/>
  </svg>
)

const ReportIcon = ({ color }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
    <path fill={color} fillRule="evenodd" clipRule="evenodd" d="M2.73438 12.9375H1.05882C0.750194 12.9375 0.5 13.1894 0.5 13.5V15.1875C0.5 16.6787 1.65289 17.8988 3.1104 17.994L3.29412 18H13.3529L13.5367 17.994C14.9942 17.8988 16.1471 16.6787 16.1471 15.1875H15.0294L15.0217 15.35C14.9405 16.2058 14.2244 16.875 13.3529 16.875C12.4815 16.875 11.7654 16.2058 11.6841 15.35L11.6765 15.1875V13.5C11.6765 13.1894 11.4263 12.9375 11.1176 12.9375H3.85202V2.25C3.85202 1.62868 4.35241 1.125 4.96967 1.125L15.3276 1.12492C15.1374 1.45588 15.0285 1.84014 15.0285 2.25V15.1875H16.1461V5.625H18.9403L19.0407 5.61594C19.3014 5.56831 19.4991 5.33864 19.4991 5.0625V2.25C19.4991 1.00736 18.4983 0 17.2638 0H4.96967C3.73515 0 2.73438 1.00736 2.73438 2.25V12.9375ZM17.2641 1.125C16.6469 1.125 16.1465 1.62868 16.1465 2.25V4.5H18.3818V2.25C18.3818 1.62868 17.8814 1.125 17.2641 1.125ZM1.62486 15.35C1.7061 16.2058 2.42223 16.875 3.29366 16.875L11.1178 16.8761C10.8104 16.4646 10.6126 15.9655 10.568 15.4227L10.5584 15.1875V14.0625H1.61719V15.1875L1.62486 15.35Z"/>
  </svg>
)

const CompassIcon = ({ color }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
    <path fill={color} d="M10.1483 11.6113L11.0304 9.14251C11.2019 8.66245 11.0846 8.14028 10.7241 7.77975C10.3636 7.41912 9.84129 7.30156 9.36091 7.47308L6.89116 8.35487C6.52089 8.4871 6.23268 8.77527 6.10036 9.1455L5.21825 11.6143C5.04673 12.0944 5.16408 12.6165 5.52453 12.9771C5.77548 13.2281 6.10475 13.3613 6.44373 13.3613C6.59177 13.3613 6.74171 13.3359 6.88771 13.2837L9.35743 12.402C9.72773 12.2697 10.016 11.9816 10.1483 11.6113ZM6.61364 11.8884L7.40866 9.66328L9.63503 8.8684L8.84001 11.0935L6.61364 11.8884Z"/>
    <path fill={color} d="M8.82812 6.14743V5.84863C8.82812 5.4603 8.51334 5.14551 8.125 5.14551C7.73666 5.14551 7.42188 5.4603 7.42188 5.84863V6.14743C7.42188 6.53576 7.73666 6.85055 8.125 6.85055C8.51334 6.85055 8.82812 6.53576 8.82812 6.14743Z"/>
    <path fill={color} d="M7.42188 14.6094V14.9082C7.42188 15.2965 7.73666 15.6113 8.125 15.6113C8.51334 15.6113 8.82812 15.2965 8.82812 14.9082V14.6094C8.82812 14.221 8.51334 13.9062 8.125 13.9062C7.73666 13.9062 7.42188 14.221 7.42188 14.6094Z"/>
    <path fill={color} d="M12.6563 9.67529H12.3574C11.9691 9.67529 11.6543 9.99008 11.6543 10.3784C11.6543 10.7668 11.9691 11.0815 12.3574 11.0815H12.6563C13.0447 11.0815 13.3594 10.7668 13.3594 10.3784C13.3594 9.99008 13.0447 9.67529 12.6563 9.67529Z"/>
    <path fill={color} d="M3.59179 9.67529C3.20346 9.67529 2.88867 9.99008 2.88867 10.3784C2.88867 10.7668 3.20346 11.0815 3.59179 11.0815H3.89069C4.27902 11.0815 4.59381 10.7668 4.59381 10.3784C4.59381 9.99008 4.27902 9.67529 3.89069 9.67529H3.59179Z"/>
    <path fill={color} d="M13.5153 4.98885C12.5282 4.00208 11.318 3.31924 9.99848 2.98733C10.1315 2.71332 10.2062 2.40592 10.2062 2.08143C10.2062 0.933715 9.27223 0 8.12423 0C6.97624 0 6.04228 0.933715 6.04228 2.08143C6.04228 2.40592 6.11695 2.71329 6.24998 2.98733C4.93046 3.31924 3.72031 4.00208 2.73316 4.98885C1.29309 6.42843 0.5 8.34244 0.5 10.3783C0.5 12.4142 1.29309 14.3282 2.73316 15.7678C4.1732 17.2072 6.08777 18 8.12423 18C9.50222 18 10.8527 17.6284 12.0298 16.9252C12.3632 16.7261 12.472 16.2944 12.2729 15.961C12.0737 15.6277 11.6421 15.5188 11.3087 15.718C10.3495 16.2909 9.24835 16.5938 8.12423 16.5938C4.69562 16.5938 1.90625 13.8055 1.90625 10.3783C1.90625 6.95201 4.69418 4.16433 8.12153 4.16285H8.12423C8.12423 4.16285 8.12603 4.16285 8.12694 4.16285C11.5543 4.16433 14.3422 6.95197 14.3422 10.3783C14.3422 11.4624 14.059 12.5294 13.5232 13.4639C13.33 13.8008 13.4466 14.2305 13.7834 14.4236C14.1203 14.6168 14.55 14.5003 14.7431 14.1634C15.4008 13.0164 15.7485 11.7075 15.7485 10.3783C15.7485 8.34244 14.9554 6.42843 13.5153 4.98885ZM7.44853 2.08143C7.44853 1.70912 7.75165 1.40625 8.12423 1.40625C8.49682 1.40625 8.79994 1.70912 8.79994 2.08143C8.79994 2.45313 8.4978 2.75562 8.12606 2.7566C8.12546 2.7566 8.12487 2.7566 8.12423 2.7566C8.1236 2.7566 8.12304 2.7566 8.12241 2.7566C7.75066 2.75562 7.44853 2.45313 7.44853 2.08143Z"/>
  </svg>
)

const activeColor = '#E2943B'
const defaultColor = '#CACDCA'

type TabItemProps = {
  href: string
  label: string
  icon: typeof CompassIcon | typeof ReportIcon | typeof HomeIcon
}

const TabItem = ({ href, label, icon }: TabItemProps) => {
  const router = useRouter()
  const active = router.pathname === href
  const Icon = icon
  const color = active ? activeColor : defaultColor

  return (
    <NextLink href={href} passHref>
      <VStack flex={1} as={'a'} pb={4} pt={1}>
        <Icon color={color}/>
        <CrownText min variant={'minAnnotation'} color={color}>{label}</CrownText>
      </VStack>
    </NextLink>
  )
}

export const FooterTabs: TabItemProps[] = [
  { label: 'ホーム', icon: HomeIcon, href: '/' },
  { label: 'アドバイザー', icon: ReportIcon, href: '/adventure_advices' },
  // { label: 'ステータス', icon: CompassIcon, href: '/' }
]

export const Footer = () => {
  const router = useRouter()

  // フッターを表示するのはフッターから遷移できる画面のみ
  if (!FooterTabs.map(item => item.href).includes(router.pathname)) return <></>
  return (
    <HStack position={'sticky'} left={0} right={0} bottom={0} spacing={0} bg={'white'}>
      {
        FooterTabs.map(itemProps =>
          <TabItem {...itemProps} key={itemProps.label} />
        )
      }
    </HStack>
  )
}
